export const data = {
  Projects: [
    {
      image: "images/projects/01.jpeg",
      url: "https://github.com/bnonni/familybtc",
      title: "Cete Family Bitcoin",
      description: "2021 MIT Bitcoin Expo Hackathon Submission",
    },
    {
      image: "images/projects/02.jpeg",
      url: "https://github.com/terminus-btc/create-ln-app",
      title: "Create LN App",
      description:
        "create-ln-app is meant to simplify the local dev env setup, so Lapp developers can stop fussing with the dev tooling and start building the future.",
    },
    {
      image: "images/projects/03.jpg",
      url: " https://github.com/atlantabitdevs/terminus-pay-server",
      title: "Terminus Pay",
      description:
        "2021 Bitcoin++ Austin Hackathon - Winner: Best Use of Core LN",
    },
    {
      image: "images/projects/04.jpeg",
      url: "https://github.com/atlantabitdevs/park-lightning",
      title: "Arion Parking",
      description: "2021 BOLT.Fun Hackathon - 1st place",
    },
    {
      image: "images/projects/05.jpg",
      url: "https://github.com/atlantabitdevs/treepay-ux",
      title: "Treepay UX",
      description: "2021 PlebFi Miami Hackathon - 2nd place",
    },
  ],
};
