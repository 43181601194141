import React from "react";

export const Timeline = () => {
  return (
    <div>
      <section
        style={{ padding: "0 1em" }}
        className="colorlib-experience"
        data-section="timeline">
        <div className="colorlib-narrow-content">
          <div className="row">
            <div
              className="col-md-6 col-md-offset-3 col-md-pull-3"
              data-animate-effect="fadeInLeft">
              <span className="heading-meta">When?</span>
              <h2 className="colorlib-heading">Timeline</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="timeline-centered">
                <article
                  className="timeline-entry"
                  data-animate-effect="fadeInLeft">
                  <div className="timeline-entry-inner">
                    <div className="timeline-icon color-1">
                      <i className="icon-pen2" />
                    </div>
                    <div className="timeline-label">
                      <h2>
                        University of Georgia - Undergraduate
                        <span> 2011-2015</span>
                      </h2>
                      <p>
                        After transferring from Kennesaw State University to
                        UGA, I changed my major from business to Communication
                        Studies and Philosophy. After many philosophy courses, I
                        decided to drop the dual major and finish with a
                        Bachelors of Arts (B.A.) in Communication Studies.
                        Subsequently, I took the LSAT and scored high enough to
                        attend Gerogia State's Law School. However, I decided a
                        career in law was not the direction I wanted for my
                        career. I then landed my first full-time role at a small
                        marketing company in North GA. After a year there, I
                        moved to an analytics-driven, digital marketing company
                        called 360i.
                      </p>
                    </div>
                  </div>
                </article>
                <article
                  className="timeline-entry"
                  data-animate-effect="fadeInLeft">
                  <div className="timeline-entry-inner">
                    <div className="timeline-icon color-2">
                      <i className="icon-pen2" />
                    </div>
                    <div className="timeline-label">
                      <h2>
                        General Assembly - Student<span> 2016</span>
                      </h2>
                      <p>
                        From summer to winter of 2016, I completed two bootcamps
                        at General Assembly to kick off my career in coding and
                        software development:
                        <br />
                        <i className="icon-arrow-right" />
                        <a
                          href="https://generalassemb.ly/education/front-end-web-development/atlanta"
                          target="_blank"
                          rel="noopener noreferrer">
                          Front End Development
                        </a>
                        , Summer 2016, 11 Week, Part Time Course
                        <br />
                        <i className="icon-arrow-right" />
                        <a
                          href="https://generalassemb.ly/education/javascript-development/atlanta"
                          target="_blank"
                          rel="noopener noreferrer">
                          JavaScript Development
                        </a>
                        , Fall 2016, 11 Week, Part Time Course
                        <br />
                        My time in these courses are what set the stage for a
                        long-term career in my passion: building! I learned that
                        I am not only passionate about coding but I could pick
                        it up and understand it quite easily. This launchpad
                        allowed me the ability to build tools at my current role
                        to expedite my daily tasks. In addition, I landed my
                        first client and started my small business, Bryan W
                        Nonni, LLC.
                      </p>
                    </div>
                  </div>
                </article>
                <article
                  className="timeline-entry"
                  data-animate-effect="fadeInTop">
                  <div className="timeline-entry-inner">
                    <div className="timeline-icon color-3">
                      <i className="icon-pen2" />
                    </div>
                    <div className="timeline-label">
                      <h2 style={{ marginBottom: 0.5 + "em" }}>
                        360i - Associate Media Manager<span> 2016-2017</span>
                      </h2>
                      <h2 style={{ marginBottom: 0.5 + "em" }}>
                        360i - Media Manager<span> 2017-2018</span>
                      </h2>
                      <h2>
                        360i - Web Developer / Testing Analyst
                        <span> 2018-2019</span>
                      </h2>
                      <p>
                        While working at 360i, I help multiple roles: Associate
                        Media Manager, Media Manager and Web Developer. During
                        these 3 years, I also started my coding journey at
                        General Assembly by taking two bootcamps: Front End Web
                        Development and JavaScript Development. I began applying
                        these skills to my work by building apps and analtyics
                        integrations to make business processes more efficient.
                        I fell in love with buiding software, and I started
                        attending classes at Georgia State part time. In 2018, I
                        landed an internship as a Software Engineer at a startup
                        called CloudTags. At this time, I was in school
                        part-time, transitioning into a Web Development role at
                        360i, learning Python and R with the Data Science team
                        at 360i and interning at CloudTags. Needless to say, I
                        had a full plate, but I made it work!
                      </p>
                    </div>
                  </div>
                </article>
                <article
                  className="timeline-entry"
                  data-animate-effect="fadeInLeft">
                  <div className="timeline-entry-inner">
                    <div className="timeline-icon color-4">
                      <i className="icon-pen2" />
                    </div>
                    <div className="timeline-label">
                      <h2>
                        Georgia State University - Undergraduate
                        <span> 2017-2019</span>
                      </h2>
                      <p>
                        After completing the bootcamps, I realized that I should
                        be coding for a living. My career aspirations completely
                        changed, and I focused all my spare time and attention
                        to writing code and building apps. I decided it would be
                        valuable to get back into a formal university education
                        environment. I took all core classes and major classes
                        towards a Bachelors of Science in Computer Science. In
                        2017 and 2018, I was part time taking 1-2 classes at a
                        time in the evenings. During 2017-2018, I also started a
                        collaborative group at 360i between media managers and
                        the Data Science group with the goal of fostering better
                        collaboration between the two business units. Media
                        managers with a desire to become more technial attended
                        after work session to learn the fundamentals of Data
                        Science, Machine Learning, Statistics, Python and R.
                        Meanwhile, I landed an internship at CloudTags and began
                        to hone my skills in a production environment. In
                        January of 2019, I quit my full time role at 360i and
                        dove head first into a full time course schedule at
                        Gerogia State. In one year, I completed 51 credits in
                        courses such as Data Structures, Algorithms, Software
                        Engineering, Web Programming, AI, ML, Cyber Security
                        among others. I graduated from Georgia State University
                        with honors, Summa Cum Laude, with a 4.05 GPA to achieve
                        a Bachelors of Science (B.S.) in Computer Science.
                      </p>
                    </div>
                  </div>
                </article>
                <article
                  className="timeline-entry"
                  data-animate-effect="fadeInTop">
                  <div className="timeline-entry-inner">
                    <div className="timeline-icon color-5">
                      <i className="icon-pen2" />
                    </div>
                    <div className="timeline-label">
                      <h2>
                        CloudTags - Software Engineering Intern
                        <span> 2018-2019</span>
                      </h2>
                      <p>
                        CloudTags was a small Atlanta startup building
                        technology in the retail space with a goal of connecting
                        online and in-store commerce. While interning here, I
                        learn basics of software engineering such as working in
                        an agile scrum team, writing tests, etc. Over the course
                        of my internship, I shifted to debugging in-production
                        applications for our customers. Technology and languages
                        I used were JavaScript, jQuery, Postgres, and Ruby. It
                        was here that I first experienced working on a MVC
                        application that uses a reverse-proxy. In early 2019,
                        the startup officially dissolved in perfect time to
                        allow me to focus exclusively on my course work at
                        Georgia State.
                      </p>
                    </div>
                  </div>
                </article>
                <article
                  className="timeline-entry"
                  data-animate-effect="fadeInTop">
                  <div className="timeline-entry-inner">
                    <div className="timeline-icon color-1">
                      <i className="icon-pen2" />
                    </div>
                    <div className="timeline-label">
                      <h2>
                        Trilogy Education & Gerogia Tech - Tutor & TA
                        <span> 2019</span>
                      </h2>
                      <p>
                        For Trilogy Education, I started tutoring students
                        around the country who were taking the data science and
                        analytics boot camp at other colleges and universities.
                        From there, I landed a Teaching Assistant role with a{" "}
                        <a
                          href="https://bootcamp.pe.gatech.edu/data/"
                          target="_blank"
                          rel="noopener noreferrer">
                          Data Science and Analytics Boot Camp at Georgia Tech
                        </a>
                        . The bootcamp was part of the Georgia Tech Professional
                        Education program and covered the following topics:
                        Pivot Tables, VBA Scripting, Fundamental Statistics,
                        Modeling, Forecasting, Python 3, NumPy, Pandas,
                        Matplotlib, API Interactions, Social Media Mining,
                        MySQL, MongoDB, ETL, HTML, CSS, Bootstrap, Dashboarding,
                        JavaScript Charting, D3.js, Geomapping with Leaflet.js,
                        Tableau, Big Data Analytics with Hadoop, Machine
                        Learning.
                      </p>
                    </div>
                  </div>
                </article>
                <article
                  className="timeline-entry"
                  data-animate-effect="fadeInTop">
                  <div className="timeline-entry-inner">
                    <div className="timeline-icon color-2">
                      <i className="icon-pen2" />
                    </div>
                    <div className="timeline-label">
                      <h2>
                        NCR, Innovation Lab, Software Engineer I, Blockchain &
                        Crypto Technical Lead<span> 2020-2022</span>
                      </h2>
                      <p>
                        Over the past 2 years at NCR, the majority of my work
                        has focused on blockchain technology and cryptocurrency.
                        This included researching topics for education, applying
                        research in developing ideas, submitting ideas for
                        patent, building those ideas into proof-of-concepts and
                        working my way into a Blockchain and Crypto Technical
                        Lead position in the Lab.
                      </p>
                      <p>
                        &nbsp;
                        <i className="icon-arrow-right" />
                        Research: Crypto, Blockchain, Bitcoin, Lightning, DeFi,
                        Decentralized Identity, Self-Sovereign Identity
                        <br />
                        &nbsp;
                        <i className="icon-arrow-right" />
                        Projects: SSI Services Provider, DeFi Research, Buy Now
                        Pay Later, NCR Coin, Pay with Lightning
                        <br />
                        &nbsp;
                        <i className="icon-arrow-right" />
                        6 patents-pending for ideas generated from blockchain
                        and crypto related ideas
                        <br />
                        &nbsp;
                        <i className="icon-arrow-right" />
                        Leading the Innovation Lab in blockchain and crypto
                        research and development projects
                        <br />
                        &nbsp;
                        <i className="icon-arrow-right" />
                        Successful development of a Pay with Lightning demo
                        application
                        <br />
                        &nbsp;
                        <i className="icon-arrow-right" />
                        Leading lab recruiting and onboarding of interns and
                        engineers for the blockchain and crypto team
                        <br />
                        &nbsp;
                        <i className="icon-arrow-right" />
                        Starting NCR Crypto Meetups to foster internal education
                        and innovation
                        <br />
                        &nbsp;
                        <i className="icon-arrow-right" />
                        Winning NCR 2021 Hackathon: blockchain-based, short-term
                        loans at PoS using smart contracts
                        <br />
                        &nbsp;
                        <i className="icon-arrow-right" />
                        Generating a new market for NCR with estimated value of
                        over $25M in revenue
                        <br />
                        &nbsp;
                        <i className="icon-arrow-right" />
                        Leading development of custom, ERC20 token - NCR Coin -
                        used for hackathon gamification
                        <br />
                        &nbsp;
                        <i className="icon-arrow-right" />
                        Leading development of ERC721 NFT auction smart contract
                        and Spring Street Capital Investment Fund smart contract
                        <br />
                        &nbsp;
                        <i className="icon-arrow-right" />
                        Leading development and integration of fully custodial
                        wallet service and transaction API
                        <br />
                        &nbsp;
                        <i className="icon-arrow-right" />
                        Leading multiple intern teams to design and build PoCs
                        in blockchain and crypto
                      </p>
                    </div>
                  </div>
                </article>
                <article
                  className="timeline-entry"
                  data-animate-effect="fadeInTop">
                  <div className="timeline-entry-inner">
                    <div className="timeline-icon color-2">
                      <i className="icon-pen2" />
                    </div>
                    <div className="timeline-label">
                      <h2>
                        LibertyX, LibertyPay, Software Engineer II
                        <span> 2022-present</span>
                      </h2>
                      <p>
                        {" "}
                        In January 2022, I started a new role as a Software
                        Engineer II on the LibertyX & LibertyPay production
                        software teams.
                      </p>
                      <p>
                        &nbsp;
                        <i className="icon-arrow-right" />
                        Working on the LibertyX & LibertyPay production
                        engineering team
                        <br />
                        &nbsp;
                        <i className="icon-arrow-right" />
                        Directly contributing to the LibertyX and LibertyPay
                        production application
                        <br />
                        &nbsp;
                        <i className="icon-arrow-right" />
                        Developing API integrations between LibertyPay and
                        multiple major currency exchanges
                        <br />
                        &nbsp;
                        <i className="icon-arrow-right" />
                        Enabling remittances from the US to 50+ new countries
                        for the LibertyPay application
                        <br />
                      </p>
                    </div>
                  </div>
                </article>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
